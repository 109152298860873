import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { UtilityService } from '../../../../services';
import { Testimonial } from '../../models/testimonial';
import { TranslateService } from '@ngx-translate/core';
import { CustomCarouselComponent } from '../../../../standalone-components/custom-carousel/custom-carousel.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { GoogleStarsComponent } from 'app/standalone-components/google-reviews/google-stars/google-stars.component';

@Component({
  selector: 'app-users-feedback',
  templateUrl: './users-feedback.component.html',
  styleUrls: ['./users-feedback.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, CustomCarouselComponent, GoogleStarsComponent]
})
export class UsersFeedbackComponent {
  @ViewChild('customCarousel') customCarousel: CustomCarouselComponent;
  @Input() componentStyle: boolean;

  isStudent = false;
  testimonials: Array<Testimonial> = [];

  private readonly STUDENT_TRANSLATIONS = [
    'texts.Price_Page_Trustpilot_Section_Testimonial_Student_1',
    'texts.Price_Page_Trustpilot_Section_Testimonial_Student_2',
    'texts.Price_Page_Trustpilot_Section_Testimonial_Student_3'
  ];

  private readonly PARENT_TRANSLATIONS = [
    'texts.Price_Page_Trustpilot_Section_Testimonial_Parent_1',
    'texts.Price_Page_Trustpilot_Section_Testimonial_Parent_2',
    'texts.Price_Page_Trustpilot_Section_Testimonial_Parent_3'
  ];

  constructor(
    public utilityService: UtilityService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.swapData(this.isStudent);
    this.cdr.detectChanges();
  }

  swapData(isStudent: boolean) {
    this.isStudent = isStudent;
    const translations = isStudent ? this.STUDENT_TRANSLATIONS : this.PARENT_TRANSLATIONS;

    this.translate.get(translations).subscribe(translation => {
      this.testimonials = translations.map(key => JSON.parse(translation[key]));
    });
  }
}
