<app-login-or-register-frame #mainComponent [isRegisteringAsTutor]="true">
    <app-select-register-type [ngClass]="{'hide-element': hideSelectRegType}" [isRegisteringAsTutor]="true"
        (onEmailRegister)="hideSelectRegType = true" class="select-register-type"
        (onCloseDialog)="dialogRef.close()"></app-select-register-type>
    <form *ngIf="registrationForm" [ngClass]="{'hide-element': !hideSelectRegType}" [formGroup]="registrationForm"
        #ngForm="ngForm" (ngSubmit)="registrationForm.valid && onSubmit()">
        <div class="back-button" *ngIf="tabs.currentTab() > 1" (click)="tabs.prevTab()"><app-svg-icon
                name="Alt_Arrow_Left"></app-svg-icon></div>
        <app-custom-tabs #tabs>
            <div class="stepper-step" *ngIf="!userData.googleId">
                <app-personal-data #personalDataRegister [isRegisteringAsTutor]="true" (onConfirm)="tabs.nextTab()"
                    [form]="registrationForm.get('personalDataForm')"></app-personal-data>
            </div>
            <div class="stepper-step">
                <app-tutor-education [isRegisteringAsTutor]="true" (onConfirm)="tabs.nextTab(); scrollToBottom()"
                    [educationForm]="registrationForm.get('educationForm')"></app-tutor-education>
            </div>
            <div class="stepper-step address-and-distance-container">
                <app-range-slider [sliderForm]="registrationForm.get('maxTravelDistance')" [minValue]="4"
                    [maxValue]="20" [title]="'texts.TutorRegistration_max_travel'"
                    [description]="'texts.Tutor_Registration_Distance_Description'"></app-range-slider>
                <app-new-address-form #addressForm [showConfirmButton]="true" [isRegisteringAsTutor]="true" (onConfirm)="tabs.nextTab()"
                    [parentForm]="registrationForm.get('locationDataForm')"></app-new-address-form>
            </div>
            <div class="stepper-step">
                <app-phone-input [isRegisteringAsTutor]="true" [phoneNumber]="registrationForm.get('tutorPhone')"
                    [heardAboutUsForm]="registrationForm.get('heardAboutUsForm')"
                    [birthDay]="registrationForm.get('birthDay')" [birthMonth]="registrationForm.get('birthMonth')"
                    [birthYear]="registrationForm.get('birthYear')"
                    (onConfirm)="submitButton.click()"></app-phone-input>
                <button #submitButton type="submit" style="display: none;">
                </button>
                <re-captcha #recaptchaComponent (resolved)="reCaptchaResolved($event)" (error)="reCaptchaError($event)"
                    [siteKey]=siteKey errorMode="handled" size="invisible">
                </re-captcha>
            </div>
        </app-custom-tabs>
    </form>
    <div #bottomContainer></div>
</app-login-or-register-frame>