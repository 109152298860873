export interface LevelYearCombinations {
	level?: Level[];
	year?: Year[];
	search: string;
}

interface Level {
	id: string;
	text: string;
	years: string;
}

interface Year {
	id: number;
	text: string;
	selected: boolean;
}

export interface GroupedCourseModel {
	courseName: string,
	schoolTypeName: string,
	schoolLevelName: string,
	courseId: string,
	schoolTypeId: string,
	schoolLevelId: string,
	schoolYearId: string
	years: Array<string>;

	//used only on UI
	_isRequested: boolean;
}

export interface GroupedCoursesModel {
	schoolLevelId: string;
	schoolLevelName: string;
	courses: Array<{
		courseId: string;
		courseName: string;
		years: Array<string>;
	}>
}

export interface CombinedCourseModel {
	approved: boolean;
	courseId: string;
	courseName: string;
	schoolLevelId: string;
	schoolLevelName: string;
	schoolTypeId: string;
	schoolTypeName: string;
	years: Array<string>;
}

export interface AddCourseModelForEdit {
	courseId: number,
	schoolTypeId: string,
	level?: {
		[key: number]: {
			yearIds: Array<string>
		}
	}
}

export interface AddCourseForRegisterModel {
	courseId: number,
	schoolTypeId: string,
	schoolLevelId?: string,
	schoolYearId?: string
}

export interface CourseModel {
	id: number;
	name: string;
}

export interface TutorCourse {
	courseName: string;
	courseID: string;
	courseLevels: SchoolLevel[];
}

export interface SchoolLevel {
	schoolLevelId?: string;
	schoolLevelName?: any;
	schoolYears?: string[];
	schoolTypeName?: string;
}

export enum CourseIcons {
	Comments = 'far fa-comments',
	Abacus = 'far fa-abacus',
	AtomAlt = 'far fa-atom-alt',
	Microscope = 'fad fa-microscope'
}