import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';
import { GoogleStarsComponent } from '../google-stars/google-stars.component';

@Component({
  selector: 'app-google-default-review',
  standalone: true,
  imports: [CommonModule, SharedModule, GoogleStarsComponent],
  templateUrl: './google-default-review.component.html',
  styleUrls: ['./google-default-review.component.scss']
})
export class GoogleDefaultReviewComponent {

  @Input() class: string;
}
