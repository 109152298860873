import { TranslateService } from '@ngx-translate/core';
import { AuthService, PopupService, UtilityService } from '../services';
import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from 'app/standalone-components/common-dialogs/confirm/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})

export class ClassroomService {

    constructor(
        private authService: AuthService,
        private translationService: TranslateService,
        private utilityService: UtilityService,
        private popupService: PopupService
    ) { }

    async joinClassroom(partnerId: string) {
        const link = await this.prepareLink(partnerId);
        const recommendationResolved = await this.recommendationBeforeJoiningClassroom();
        if (recommendationResolved) {
            this.openLessonSpace(link);
        }
    }

    async openRecording(partnerId: string, recordingLink: string) {
        const link = await this.prepareLink(partnerId);
        this.openLessonSpace(`${link}?recording=${encodeURIComponent(recordingLink)}`);
    }

    private prepareLink(partnerId: string): Promise<string> {
        const page = this.authService.isTeacher() ? 'ROUTES.Misc_relation' : 'ROUTES.Misc_profile';
        return new Promise((resolve) => {
            this.translationService.get([
                page,
                'ROUTES.Lessonspace_workarea'
            ]).subscribe(translations => {
                resolve(`${translations[page]}/${translations['ROUTES.Lessonspace_workarea']}/${partnerId}`);
            });
        });
    }

    private recommendationBeforeJoiningClassroom(): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.utilityService.isDesktopRes || !this.utilityService.isChrome) {
                const dialog = this.popupService.openResponsiveDialog(ConfirmDialogComponent).componentInstance;
                dialog.dialogTitle = 'texts.Recommendation_Before_Joining_Classroom_Title';
                dialog.dialogText = 'texts.Recommendation_Before_Joining_Classroom_Description';
                dialog.dialogConfirmButton = 'texts.Recommendation_Before_Joining_Classroom_Button';
                dialog.onConfirm.subscribe(() => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    private openLessonSpace(link: string) {
        window.open(link);
    }
}