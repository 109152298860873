import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SharedModule } from 'app/modules/shared/shared.module';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { StudentRegisterDialogComponent } from '../student-register-dialog/student-register-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService } from 'app/services';
import { UtilityService } from '../../../services/utility.service';

@Component({
  selector: 'app-login-or-register-frame',
  templateUrl: './login-or-register-frame.component.html',
  styleUrls: ['./login-or-register-frame.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, LoginDialogComponent, StudentRegisterDialogComponent]
})
export class LoginOrRegisterFrame {

  @Input() isCloseButtonVisible: boolean = true;
  @Input() isRegisteringAsTutor: boolean = false;

  previousUrl: any;

  constructor(
    public utilityService: UtilityService,
    private dialogRef: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private popupService: PopupService
  ) { }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    if (!queryParams['isLoginOrRegisterOpen']) {
      this.utilityService.addOpenQueryParam('isLoginOrRegisterOpen', this.route, this.router);
    }
    this.previousUrl = this.router.url;
  }

  closeDialogAndNavigate() {
    this.closeDialog();
    this.router.navigate(['/']);
  }

  closeDialog() {
    this.handleQueryParams();
    this.dialogRef.closeAll();
    this.popupService.onClose.emit();
  }

  handleQueryParams() {
    const subscription = this.route.queryParams.subscribe(params => {
      const queryParams = { ...params };
      delete queryParams['isLoginOrRegisterOpen'];
      this.router.navigateByUrl(this.previousUrl || '/');
    });
    subscription.unsubscribe();
  }
}