import { RangeSliderComponent } from './../range-slider/range-slider.component';
import { RegisterBaseComponent } from './../base-classes/register-base-class.component';
import { SharedModule } from '../../../modules/shared/shared.module';
import { RegisterUserRequest } from './../../../microservice-clients/user';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginOrRegisterFrame } from '../login-or-register-frame/login-or-register-frame.component';
import { FormGroup, Validators } from '@angular/forms';
import { NewAddressFormComponent } from '../../../standalone-components/new-address-form/new-address-form.component';
import { PhoneInputComponent } from '../phone-input/phone-input.component';
import { MessageType } from '../../../constants';
import { RecaptchaModule } from 'ng-recaptcha';
import { SelectRegisterTypeComponent } from '../select-register-type/select-register-type.component';
import { PersonalDataComponent } from '../personal-data/personal-data.component';
import { CustomTabsComponent } from '../../../standalone-components/custom-tabs/custom-tabs.component';
import { TutorEducationComponent } from '../../../standalone-components/tutor-education/tutor-education.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CookieOptions } from '../../../services/custom-cookie-service.service';


@Component({
  selector: 'app-tutor-register-dialog',
  standalone: true,
  imports: [CommonModule, SharedModule, RecaptchaModule, LoginOrRegisterFrame, MatDatepickerModule, MatInputModule, CustomTabsComponent, PersonalDataComponent, TutorEducationComponent, NewAddressFormComponent, PhoneInputComponent, SelectRegisterTypeComponent, RangeSliderComponent],
  templateUrl: './tutor-register-dialog.component.html',
  styleUrls: ['./tutor-register-dialog.component.scss']
})
export class TutorRegisterDialogComponent extends RegisterBaseComponent<TutorRegisterDialogComponent> implements OnInit {

  @ViewChild('addressForm') addressForm: NewAddressFormComponent
  @ViewChild('bottomContainer') bottomContainer: ElementRef;

  async ngOnInit() {
    this.utilityService.trackEvent('registration_tutor', 'Started', 'Success');
    const tutorRegProgress = +this.cookieService.get('tutorregprogress');
    if (tutorRegProgress < 1) {
      const cookieOptions: CookieOptions = {
        expires: (86400 * 30).toString(),
      };
      this.cookieService.put('tutorregprogress', "1", cookieOptions);
      this.utilityService.trackEvent('tutor_registration_1', 'tutor_registration_start');
      this.utilityService.trackEvent('form_part_1', '', 'register_popup_1');
    }
    await this.initForm()
    if (!this.userData.googleId) {
      this.addPersonalDataToForm();
    }
    this.checkToAutofill()
    this.setRecaptcha()
    this.registrationForm.get('birthDate')?.valueChanges.subscribe(value => {
    })
  }

  autoFillData() {
    const email = `${Math.round(Math.random() * 1000000)}-bah@mailinator.com`;
    const personalDataForm = this.registrationForm.get('personalDataForm')!;
    personalDataForm.get('email')?.setValue(email);
    personalDataForm.get('password')?.setValue('Test12345');
    personalDataForm.get('firstName')?.setValue('Mark');
    personalDataForm.get('lastName')?.setValue('II');
    this.personalDataRegister.validatePassword('Test12345')

    const educationForm = this.registrationForm.get('educationForm')!;
    educationForm.get('highestEducation')?.setValue('VWO');
    educationForm.get('currentEducation')?.setValue('HBO');

    const locationDataForm = this.registrationForm.get('locationDataForm')!;
    locationDataForm.get('postCode')?.setValue('3555HX')
    locationDataForm.get('houseNumber')?.setValue('656')
    this.addressForm.updateFields()

    this.registrationForm.get('tutorPhone')?.setValue('31612345678')
    this.registrationForm.get('birthDay')?.setValue('1')
    this.registrationForm.get('birthMonth')?.setValue('1')
    this.registrationForm.get('birthYear')?.setValue('1990')
    this.registrationForm.get('heardAboutUsForm')?.setValue('62bc33a0cfc469226f360a82')
  }

  scrollToBottom() {
    this.bottomContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  mapLocationData() {
    const locationDataForm = this.registrationForm.get('locationDataForm');
    const locationData = {
      city: locationDataForm?.get('city')?.value,
      houseNumber: locationDataForm?.get('houseNumber')?.value,
      postalCode: locationDataForm?.get('postCode')?.value,
      street: locationDataForm?.get('street')?.value,
      country: this.utilityService.country,
      coordinates: this.addressForm.geoService.coordinates,
      state: this.addressForm.geoService.state
    }
    return locationData;
  }

  triggerToast(id: string) {
    this.toastService.showMsg('texts.Misc_Successful_Registration_Tutor', MessageType.success, { title: 'texts.Misc_Successful_Registration_Title_Tutor', timeout: 'stick' });
    this.utilityService.trackEvent('registration_tutor', 'Ended', 'Success', id);
  }

  private get formattedDate(): string {
    return `${this.registrationForm.get('birthYear')?.value}-${this.registrationForm.get('birthMonth')?.value.toString().padStart(2, '0')}-${this.registrationForm.get('birthDay')?.value.toString().padStart(2, '0')}T00:00:00.000Z`;
  }

  createRegistrationRequestData() {
    const personalDataForm = this.registrationForm.get('personalDataForm') as FormGroup;
    const educationForm = this.registrationForm.get('educationForm') as FormGroup;
    this.registerUserRequest = {
      user: {
        personalInfo: {
          forename: this.userData.googleId ? this.userData.firstName : personalDataForm?.get('firstName')?.value,
          surname: this.userData.googleId ? this.userData.lastName : personalDataForm?.get('lastName')?.value,
          emailAddress: this.userData.googleId ? this.userData.email : personalDataForm?.get('email')?.value,
          phoneNumber: this.registrationForm.get('tutorPhone')?.value,
          birthDate: this.formattedDate,
          location: this.mapLocationData()
        },
        tutorDetails: {
          highestEducation: educationForm?.get('highestEducation')?.value.toString(),
          currentEducation: educationForm?.get('currentEducation')?.value.toString(),
          maxTravelDistance: this.registrationForm.get('maxTravelDistance')?.value,
        },
        country: this.utilityService.country,
        role: 'Tutor',
        heardAbout: this.registrationForm.get('heardAboutUsForm')?.value
      },
      password: personalDataForm?.get('password')?.value,
      captcha: this.captcha_value,
      googleId: this.userData.googleId,
    } as RegisterUserRequest;

    const tutorRegProgress = +this.cookieService.get('tutorregprogress');
    if (tutorRegProgress < 6) {
      const cookieOptions: CookieOptions = {
        expires: (86400 * 30).toString(),
      };
      this.cookieService.put('tutorregprogress', "6", cookieOptions);
      this.utilityService.trackEvent('form_part_1', '', 'phone_source_success_6');
    }
  }

  private async initForm(): Promise<void> {
    const prefix = this.translationService.instant('texts.Misc_field_mobile_phone_prefix');
    this.phoneNumberUtil = await this.utilityService.addGooglePhoneNumberLib();
    this.registrationForm = this.formBuilder.group({
      locationDataForm: this.formBuilder.group({
        city: ['', Validators.required],
        houseNumber: ['', Validators.required],
        postCode: ['', [Validators.required]],
        street: ['', Validators.required],
      }),
      educationForm: this.formBuilder.group({
        highestEducation: [null, [Validators.required]],
        currentEducation: [null, [Validators.required]]
      }),
      maxTravelDistance: [7, [Validators.required, Validators.min(3), Validators.max(20)]],
      tutorPhone: [prefix, {
        validators: [Validators.required, this.utilityService.phonePatternValidator(this.phoneNumberUtil)],
        updateOn: 'blur'
      }],
      birthDay: ['', [Validators.required, Validators.min(1), Validators.max(31)]],
      birthMonth: ['', [Validators.required, Validators.min(1), Validators.max(12)]],
      birthYear: ['', [Validators.required, Validators.min(1940), Validators.max(2020)]],
      heardAboutUsForm: ['', [Validators.required]]
    });
  }
}
