import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewAddressFormBaseComponent } from './new-addres-form-base';
import { SharedModule } from 'app/modules/shared/shared.module';
import { CookieOptions } from '../../services/custom-cookie-service.service';


@Component({
  selector: 'app-new-address-form',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './new-address-form.component.html',
  styleUrls: ['./new-address-form.component.scss']
})
export class NewAddressFormComponent extends NewAddressFormBaseComponent {
  @Input('showConfirmButton') showConfirmButton: boolean = false;
  @Input() isRegisteringAsTutor: boolean;
  @Output('onConfirm') onConfirm = new EventEmitter();

  showError: boolean = false;

  async confirm() {
    await this.geoService.validateAddress();
    if (this.geoService.isAddressValid) {
      if (this.isRegisteringAsTutor) {
        const tutorRegProgress = +this.cookieService.get('tutorregprogress');
        if (tutorRegProgress < 5) {
          const cookieOptions: CookieOptions = {
            expires: (86400 * 30).toString(),
          };
          this.cookieService.put('tutorregprogress', "5", cookieOptions);
          this.utilityService.trackEvent('form_part_1', '', 'travel_address_success_5');
        }
      }
      this.onConfirm.emit();
    } else {
      this.showError = true;
    }
  }
}
