import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({ name: 'dateWithLocale' })
export class DateWithLocalePipe implements PipeTransform {

    transform(input: dayjs.Dayjs, format: string = 'DD-MM-YYYY'): any {
        if (!input) {
            return null;
        }
        const dayName = input.format(format);
        return dayName;
    }
}