import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { Injectable, Optional, Inject } from "@angular/core";
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import 'dayjs/locale/de';

@Injectable()
export class CustomDateAdapter extends DateAdapter<Dayjs> {
    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super();
        this.setLocale(dateLocale || 'en');
    }

    getYear(date: Dayjs): number {
        return date.year();
    }

    getMonth(date: Dayjs): number {
        return date.month();
    }

    getDate(date: Dayjs): number {
        return date.date();
    }

    getDayOfWeek(date: Dayjs): number {
        return date.day();
    }

    getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        const format = style === 'long' ? 'MMMM' : style === 'short' ? 'MMM' : 'M';
        return Array.from({ length: 12 }, (_, i) =>
            dayjs().month(i).format(format)
        );
    }

    getDateNames(): string[] {
        return Array.from({ length: 31 }, (_, i) => String(i + 1));
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        const format = style === 'long' ? 'dddd' : style === 'short' ? 'ddd' : 'dd';
        return Array.from({ length: 7 }, (_, i) =>
            dayjs().day(i).format(format)
        );
    }

    getYearName(date: Dayjs): string {
        return date.format('YYYY');
    }

    getFirstDayOfWeek(): number {
        return 1; // Monday
    }

    getNumDaysInMonth(date: Dayjs): number {
        return date.daysInMonth();
    }

    clone(date: Dayjs): Dayjs {
        // Create a completely new dayjs object
        return dayjs(date.format());
    }

    createDate(year: number, month: number, date: number): Dayjs {
        // Create a new dayjs object with the specified date
        return dayjs().year(year).month(month).date(date);
    }

    today(): Dayjs {
        return dayjs().startOf('day');
    }

    parse(value: any, parseFormat: string): Dayjs | null {
        if (value && typeof value === 'string') {
            return dayjs(value, parseFormat);
        }
        if (value) {
            return dayjs(value);
        }
        return null;
    }

    format(date: Dayjs, displayFormat: string): string {
        return date.format(displayFormat);
    }

    addCalendarYears(date: Dayjs, years: number): Dayjs {
        return dayjs(date.add(years, 'year').format());
    }

    addCalendarMonths(date: Dayjs, months: number): Dayjs {
        return dayjs(date.add(months, 'month').format());
    }

    addCalendarDays(date: Dayjs, days: number): Dayjs {
        return dayjs(date.add(days, 'day').format());
    }

    toIso8601(date: Dayjs): string {
        return date.toISOString();
    }

    deserialize(value: any): Dayjs | null {
        if (value instanceof Date) {
            return dayjs(value);
        }
        if (typeof value === 'string') {
            return dayjs(value);
        }
        if (value && typeof value === 'object' && value.$d) {
            // This is already a dayjs object
            return dayjs(value.format());
        }
        return null;
    }

    isDateInstance(obj: any): boolean {
        return dayjs.isDayjs(obj);
    }

    isValid(date: Dayjs): boolean {
        return date && date.isValid();
    }

    invalid(): Dayjs {
        return dayjs('invalid date');
    }

    // This method is crucial for month navigation
    compareDate(first: Dayjs, second: Dayjs): number {
        if (!first || !second) return 0;

        // Convert to timestamps for reliable comparison
        const firstTime = first.valueOf();
        const secondTime = second.valueOf();

        if (firstTime < secondTime) {
            return -1;
        } else if (firstTime > secondTime) {
            return 1;
        } else {
            return 0;
        }
    }

    // This method is used by the calendar
    clampDate(date: Dayjs, min?: Dayjs, max?: Dayjs): Dayjs {
        if (min && this.compareDate(date, min) < 0) {
            return min;
        }
        if (max && this.compareDate(date, max) > 0) {
            return max;
        }
        return date;
    }

    // This method is used by the calendar
    getValidDateOrNull(obj: any): Dayjs | null {
        return (this.isDateInstance(obj) && this.isValid(obj)) ?
            dayjs(obj.format()) : null;
    }

    setLocale(locale: string): void {
        super.setLocale(locale);

        try {
            // Try to set the locale directly
            dayjs.locale(locale);
        } catch (e) {
            console.warn(`Locale ${locale} not found, falling back to default`);
            dayjs.locale('en');
        }
    }
}