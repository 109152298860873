import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';
import { AuthService, PopupService, UtilityService } from 'app/services';
import { RegisterType } from 'app/microservice-clients/user';
import { CookieOptions, CustomCookieServiceService } from '../../../services/custom-cookie-service.service';

@Component({
  selector: 'app-select-register-type',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './select-register-type.component.html',
  styleUrls: ['./select-register-type.component.scss']
})
export class SelectRegisterTypeComponent {

  constructor(
    public authService: AuthService,
    public popupService: PopupService,
    public utilityService: UtilityService,
    private cookieService: CustomCookieServiceService
  ) { }

  @Output() onEmailRegister = new EventEmitter();
  @Output() onCloseDialog = new EventEmitter();

  @Input() isRegisteringAsTutor: boolean;

  loginWithGoogle() {
    this.authService.loginWithGoogle();
    if (this.isRegisteringAsTutor) {
      localStorage.setItem(RegisterType.isTutorRegister, 'true');
    }
  }

  openLogin() {
    this.popupService.showLoginModal();
    this.onCloseDialog.emit()
  }

  openStudentRegister() {
    this.popupService.showRegisterModalStudent();
    this.onCloseDialog.emit()
  }

  registerProgressAnalytics(isGoogleLogin: boolean = false) {
    if (this.isRegisteringAsTutor) {
      const tutorRegProgress = +this.cookieService.get('tutorregprogress');
      if (tutorRegProgress < 2) {
        const cookieOptions: CookieOptions = {
          expires: (86400 * 30).toString(),
        };
        this.cookieService.put('tutorregprogress', "2", cookieOptions);
        if (isGoogleLogin) {
          this.utilityService.trackEvent('form_part_1', '', 'choose_google_2');
        } else {
          this.utilityService.trackEvent('form_part_1', '', 'choose_email_2')
        }
      }
    }
  }
}
