import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SharedModule } from 'app/modules/shared/shared.module';
import { animate, style, transition, trigger } from '@angular/animations';
import { InputRequiredErrorComponent } from 'app/standalone-components/input-required-error/input-required-error.component';
import { UtilityService } from '../../../services';
import { CookieOptions, CustomCookieServiceService } from '../../../services/custom-cookie-service.service';

@Component({
  selector: 'app-personal-data',
  standalone: true,
  imports: [CommonModule, SharedModule, InputRequiredErrorComponent],

  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  animations: [
    trigger('errorMessage', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})

export class PersonalDataComponent {
  @Output() onConfirm = new EventEmitter();

  @Input() form: FormGroup;
  @Input() isRegisteringAsTutor: boolean = false;

  hidePassword = true;
  passwordTouched = false;
  minLengthMet: boolean = false;
  uppercaseMet: boolean = false;
  lowercaseMet: boolean = false;
  numberMet: boolean = false;
  showError: boolean = false;
  isEmailDuplicate: boolean = false;
  isPasswordValid: boolean = true;
  currentEmailCheck: Subscription;
  emailDuplicateState: boolean = false;
  emailFormatInvalid: boolean;

  constructor(
    private cookieService: CustomCookieServiceService,
    private utilityService: UtilityService
  ) { }

  validateEmailInput(): void {
    if (!this.form.controls.email.errors?.pattern) {
      this.emailFormatInvalid = false;
      this.emailDuplicateState = false;
    } else {
      this.emailFormatInvalid = true;
      this.emailDuplicateState = false;
    }
  }

  validatePassword(password: string) {
    this.passwordTouched = true;
    this.minLengthMet = password.length >= 8;
    this.uppercaseMet = /[A-Z]/.test(password);
    this.lowercaseMet = /[a-z]/.test(password);
    this.numberMet = /[0-9]/.test(password);
    this.isPasswordValid = this.minLengthMet && this.uppercaseMet && this.lowercaseMet && this.numberMet;
  }

  confirm(): void {
    this.isPasswordValid = this.minLengthMet && this.uppercaseMet && this.lowercaseMet && this.numberMet;
    if (this.form.controls.email.valid &&
      this.isPasswordValid &&
      !this.emailDuplicateState &&
      this.form.controls.firstName.valid &&
      this.form.controls.lastName.valid) {
      if (this.isRegisteringAsTutor) {
        const tutorRegProgress = +this.cookieService.get('tutorregprogress');
        if (tutorRegProgress < 3) {
          const cookieOptions: CookieOptions = {
            expires: (86400 * 30).toString(),
          };
          this.cookieService.put('tutorregprogress', "3", cookieOptions);
          this.utilityService.trackEvent('form_part_1', '', 'email_success_3');
        }
      }
      this.onConfirm.emit();
    }
    else {
      this.showError = true;
    }
  }
}
