<div class="search-dialog-container">
    <div class="bah-logo-container">
        <img class="bah-logo" [src]="'images.Misc_logo_rebrand.originalSource' | translate"
            [alt]="'images.Misc_logo_rebrand.title' | translate">
    </div>
    <div class="content-container">
        <div class="white-container stepper-container">
            <app-custom-stepper [showPagination]="false" [progressBarHeight]="8" [totalSteps]="tabs.totalTabs"
                [currentStep]="tabs.currentTab"></app-custom-stepper>
        </div>
        <div class="white-container content-container-and-buttons-container">
            <div class="content-container" #topContainer>
                <app-custom-tabs #tabs [showStepper]="false">
                    <div class="tab">
                        <div class="title" translate>texts.Search-Dialog-Schooltype-Title</div>
                        <div class="radio-buttons-container">
                            <div *ngFor="let schoolType of searchService.schoolTypes" class="radio-button-container"
                                (click)="onSchoolTypeSelect(schoolType)"
                                [ngClass]="{'panel-active': schoolType.id === searchService.selectedSchoolTypeId}">
                                <div class="radio-button"
                                    [ngClass]="{'radio-active': schoolType.id === searchService.selectedSchoolTypeId}">
                                </div>
                                <div class="radio-text" translate>{{schoolType.name}}
                                </div>
                            </div>
                        </div>
                        <div class="dropdowns-container">
                            <div class="dropdown-container" *ngIf="showLevels">
                                <div translate class="title small-title">
                                    texts.Search-Dialog-School-Level-Title</div>
                                <ng-select (change)="onLevelSelect($event)" class="level-dropdown dropdown"
                                    [searchable]="false" (click)="scroll()" dropdownPosition="bottom"
                                    [clearable]="false"
                                    placeholder="{{ 'texts.Search-Dialog-Schooltype-Placeholder' | translate }}">
                                    <ng-option *ngFor="let level of searchService.schoolTypes[1].levels" [value]=level
                                        [ngClass]="{'option-active': level.id === searchService.selectedLevelId}">
                                        <span translate>{{ level.name }}</span>
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="dropdown-container" *ngIf="showYears">
                                <div translate class="title small-title">
                                    texts.Search-Dialog-Schoolyear-Title</div>
                                <ng-select (change)="onYearSelect($event)" bindValue="id" class="year-dropdown dropdown"
                                    [searchable]="false" dropdownPosition="bottom" (click)="scroll()"
                                    [clearable]="false"
                                    placeholder="{{ 'texts.Search-Dialog-Schoolyear-Placeholder' | translate }}"
                                    [(ngModel)]="searchService.selectedYearId">
                                    <ng-option *ngFor="let year of searchService.selectedSchoolLevel.years"
                                        [value]=year>
                                        <span translate>{{ year.name }}</span>
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="tab">
                        <div class="title small-title" translate>texts.Search-Dialog-Select-Course</div>
                        <div class="courses-container">
                            <div *ngFor="let course of searchService.availableCourses" class="course-button"
                                (click)="searchService.addOrRemoveCourses(course); checkIfNextDisabled()"
                                [ngClass]="{'selected-subject': searchService.selectedCourseIds.includes(course.id)}">
                                {{course.name}}
                                <app-svg-icon name="Close_Black" class="close-icon"
                                    [ngClass]="{'close-icon-appear': searchService.selectedCourseIds.includes(course.id)}"></app-svg-icon>
                                <div class="button-overlay"
                                    [ngClass]="{'button-overlay-fill': searchService.selectedCourseIds.includes(course.id)}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab">
                        <div class="title small-title" translate>texts.Search-Dialog-Lesson-Type-Title
                        </div>
                        <div class="lesson-type-container">
                            <div class="radio-buttons-container">
                                <div class="radio-button-container radio-online"
                                    (click)="selectAtHomeOrOnline(LessonTypeEnum.Online)"
                                    [ngClass]="{'panel-active':  lessonType === LessonTypeEnum.Online}">
                                    <div class="radio-button"
                                        [ngClass]="{'radio-active': lessonType === LessonTypeEnum.Online  }">
                                    </div>
                                    <div class="radio-text" translate>
                                        <div class="online-text-upper" translate>texts.Search-Dialog-Lesson-Type-Online
                                            <app-svg-icon name="star"></app-svg-icon>

                                            <div class="online-text-bottom" translate>
                                                texts.Search-Dialog-Lesson-Type-Online-Description </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="radio-button-container"
                                    (click)="selectAtHomeOrOnline(LessonTypeEnum.AtHOme)"
                                    [ngClass]="{'panel-active': lessonType === LessonTypeEnum.AtHOme}">
                                    <div class="radio-button"
                                        [ngClass]="{'radio-active': lessonType === LessonTypeEnum.AtHOme}">
                                    </div>
                                    <div class="radio-text" translate>texts.Search-Dialog-Lesson-Type-Home
                                    </div>
                                </div>
                            </div>
                            <div class="search-option input-field" *ngIf="lessonType === LessonTypeEnum.AtHOme">
                                <app-svg-icon class="search-icon" name="map-point"></app-svg-icon>
                                <input type="text" class="post-code" (ngModelChange)="postCodeInputChange.next($event)"
                                    [placeholder]="'texts.SearchBarHeaderSection_post_code' | translate"
                                    [ngClass]="{'input-error': searchService.showPostCodeError, 'input-correct': searchService.postCode?.length > 5 && !searchService.showPostCodeError}"
                                    [disabled]="searchService.isOnline" maxlength="7"
                                    [(ngModel)]="searchService.postCode"
                                    [readonly]="authService.userDetails && authService.userDetails?.state !== UserStateEnum.New"
                                    data-cy="PostCode">
                                <div class="searching-indicator" *ngIf="isSearchingPostCode()">
                                </div>
                            </div>
                            <div *ngIf="!searchService.isOnline" class="postcode-error"
                                [ngClass]="{'show-error': searchService.showPostCodeError}">
                                {{'texts.TutorSearchDropdownsComponent_postcode_not_valid' |
                                translate }}</div>
                        </div>
                    </div>
                </app-custom-tabs>
            </div>
            <div #bottomContainer class="buttons-container">
                <button class="button back-button" [ngClass]="{'back-button-visible': tabs.currentTab() > 1}"
                    (click)="prevStep()" translate>texts.Search-Dialog-Back</button>
                <button class="button" [ngClass]="{'button-disabled': isNextDisabled || isSearchingPostCode()}"
                    (click)="!isNextDisabled && nextStep()">{{(tabs.currentTab() === 3 ?
                    'texts.Search-Dialog-Search-Button' : 'texts.Search-Dialog-Next') | translate
                    }}</button>
            </div>
        </div>
    </div>
</div>