<div class="registration-type-step">
    <div class="dialog-title before-steps-title" *ngIf="!isRegisteringAsTutor" translate>
        texts.Student_Registration_Dialog_Title</div>
    <div class="dialog-title before-steps-title" *ngIf="isRegisteringAsTutor" translate>
        texts.Tutor_Registration_Dialog_Title</div>
    <div class="option-button button" (click)="loginWithGoogle(); registerProgressAnalytics(true)">
        {{'texts.Misc_register_with_Google' | translate}} &nbsp;<img class="google-logo" src="https://media.bijlesaanhuis.nl/logos/google-reviews-logo.png">
    </div>
    <div class="or-text decorated-text" translate>texts.Misc_Or</div>
    <div class="option-button button" (click)="onEmailRegister.emit(); registerProgressAnalytics()">
        <div translate>texts.Misc_Register_With_Email_Button &nbsp;</div>
        <div><app-svg-icon name="mail-icon"></app-svg-icon></div>
    </div>
    <div class="bottom-info"><a class="hyperlink" (click)="openLogin()"
            translate>texts.New_login_or_register_dialog_login_button_text</a>
        <span translate>texts.Misc_Or</span>
        <a *ngIf="!isRegisteringAsTutor" class="hyperlink" (click)="popupService.showRegisterModalTutor();
            onCloseDialog.emit()" translate>texts.Misc_register_as_tutor</a>
        <a *ngIf="isRegisteringAsTutor" class="hyperlink" (click)="openStudentRegister()"
            translate>texts.Misc_register_as_student</a>
    </div>
</div>