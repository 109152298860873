import { Injectable } from '@angular/core';
import { SearchLevel, SearchSchoolType, Course, SearchYear } from '../models/search-school.model';
import { Coordinates, FindTutorsRequest, FindTutorsResponse, UserStateEnum } from 'app/microservice-clients/user';
import { UtilityService } from './utility.service';
import { UserClientService } from './user-client.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { GeoService } from './geo.service';
import { SearchFilterQueryParams } from 'app/models';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CourseIcons } from '../models/course.model';

const minRange = 20;
@Injectable({
  providedIn: 'root'
})
export class SearchService {

  schoolTypes: Array<SearchSchoolType> = [];
  schoolTypesReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  selectedSchoolLevel: SearchLevel;
  selectedLevelId: number;
  selectedSchoolTypeId: number;
  selectedYearId: number;
  selectedYear: SearchYear;
  selectedCourseIds: number[] | null = [];
  selectedCourses: Array<Course> = [];

  popularCourses: Array<Course> = [];
  allCourses: Array<Course> = [];
  availableCourses: Array<Course> = [];

  isOnline: boolean;
  allowToShowIsRecommended: boolean = false;

  postCode: string
  searchCoordinates: Coordinates;
  showPostCodeError: boolean;

  // The random seed used to keep ordering of the same search
  randomSeed: number;

  searchFilterQueryParams: SearchFilterQueryParams = {};

  storedScrollPosition: number;

  backToScrollView: boolean;

  constructor(
    private utilityService: UtilityService,
    private userClientService: UserClientService,
    private authService: AuthService,
    private geoService: GeoService,
    private router: Router,
    private translationService: TranslateService
  ) {
    this.getSchoolTypes();
    if (this.authService.userDetails?.personalInfo.location) {
      this.postCode = this.authService.userDetails.personalInfo.location.postalCode;
    } else {
      const sub = this.authService.onCurrentUserChange.subscribe(user => {
        this.postCode = user?.personalInfo.location?.postalCode ? user.personalInfo.location.postalCode : undefined;
        sub.unsubscribe();
      })
    }
  }

  clearAll() {
    this.selectedSchoolLevel = null;
    this.selectedLevelId = null;
    this.selectedSchoolTypeId = null;
    this.selectedYear = null;
    this.selectedYearId = null;
    this.selectedCourses = [];
    this.selectedCourseIds = [];

    if (!this.authService.userDetails || this.authService.userDetails?.state === UserStateEnum.New) {
      this.postCode = null;
      this.searchCoordinates = null;
    }
  }

  onLevelSelect(level: SearchLevel) {
    this.selectedYearId = null;
    this.selectedYear = null;

    if (!level || this.selectedSchoolLevel?.id == level.id) {
      this.selectedSchoolLevel = null;
      this.selectedSchoolTypeId = null;
      this.selectedLevelId = null
      this.availableCourses = this.allCourses;
      return;
    }
    this.setLevel(level)
  }

  setLevel(level: SearchLevel) {
    this.selectedSchoolLevel = level;
    this.selectedLevelId = level.id
    this.selectedSchoolTypeId = level._schoolTypeId;
    this.setAvailableCoursesByLevel();
    this.filterSelectedCoursesByAvailableCourses();
  }

  private getSchoolTypes() {
    this.userClientService.getAllSearchSchoolTypesAndCourses().subscribe(resp => {
      this.schoolTypes = resp.schoolTypes.map(schoolType => {
        const updatedLevels = schoolType.levels.map(level => {
          return { ...level, _schoolTypeId: schoolType.id };
        });
        return { ...schoolType, levels: updatedLevels };
      });
      this.setAllCourses();

      this.availableCourses = this.allCourses;
      this.schoolTypesReady.next(true);
    })
  }

  setAvailableCoursesByYearCheckbox(year: SearchYear) {
    if (year.id === this.selectedYearId) {
      this.selectedYear = null;
      this.selectedYearId = null;
      this.setAvailableCoursesByLevel()
    }
    else {
      this.selectedYear = year;
      this.selectedYearId = year.id;
      this.availableCourses = [];
      this.createAvailableCourses(this.availableCourses, year.courses)
      this.filterSelectedCoursesByAvailableCourses();
    }
  }

  setAvailableCoursesByYearNgSelect(year: SearchYear) {
    this.availableCourses = [];
    if (year) {
      this.createAvailableCourses(this.availableCourses, year.courses);
      this.selectedYear = year;
    } else {
      this.selectedYear = null;
      if (this.selectedSchoolLevel) {
        this.setAvailableCoursesByLevel()
      } else {
        this.availableCourses = this.allCourses;
      }
    }
    this.filterSelectedCoursesByAvailableCourses();
  }

  addOrRemoveCourses(course: Course) {
    if (
      this.selectedCourseIds.includes(course.id)) {
      const removeIndex = this.selectedCourseIds.indexOf(course.id)
      this.selectedCourseIds.splice(removeIndex, 1)
      this.selectedCourses.splice(removeIndex, 1)
    } else {
      this.selectedCourseIds = [...this.selectedCourseIds, course.id]
      this.selectedCourses.push(course);
    }
  }

  search(
    offset: number = 0,
  ): Observable<FindTutorsResponse> {

    let tutorSearch: FindTutorsRequest = {
      country: this.utilityService.country,
      offset: offset,
      limit: minRange,
      lessonType: this.searchCoordinates && this.searchCoordinates.longitude && this.searchCoordinates.latitude ? (this.isOnline ? 'Online' : 'AtHome') : 'Online',
      longitude: this.searchCoordinates?.longitude,
      latitude: this.searchCoordinates?.latitude,
      schoolLevelId: this.selectedLevelId,
      schoolYearId: this.selectedYearId,
      randomSeed: this.randomSeed
    } as FindTutorsRequest;

    if (!this.authService.user) {
      tutorSearch.anonymousId = this.authService.anonymousId;
    }

    if (this.selectedSchoolTypeId && this.selectedCourseIds?.length > 0) {
      tutorSearch.schoolTypeId = this.selectedSchoolTypeId;
      tutorSearch.courseIds = this.selectedCourseIds;
    } else if (this.selectedCourseIds?.length > 0) {
      tutorSearch.courseIds = this.selectedCourseIds;
    } else if (this.selectedSchoolTypeId) {
      tutorSearch.schoolTypeId = this.selectedSchoolTypeId;
    } else {
      tutorSearch.schoolTypeId = this.schoolTypes[0].id;
    }

    return this.userClientService.findTutors(tutorSearch).pipe(tap(resp => {
      this.randomSeed = resp.randomSeed;
    }))
  }

  allowShowIsRecommended() {
    if (this.selectedCourseIds.length > 0 ||
      this.selectedSchoolTypeId ||
      this.selectedLevelId ||
      this.searchCoordinates
    ) {
      this.allowToShowIsRecommended = true;
    }
  }


  validatePostCodeAndSetCoordinates() {
    return new Promise(resolve => {
      const postCodeValue = this.postCode.replace(/\s+/g, '');
      if (this.utilityService.isValidPostCode(postCodeValue)) {
        this.postCode = postCodeValue;
        this.geoService.getCoordinatedByZip(this.postCode).subscribe({
          next: (response) => {
            this.showPostCodeError = false;
            this.setSearchCoordinates({ latitude: response.coordinates.lat, longitude: response.coordinates.long });
            resolve(true)
          },
          error: () => {
            this.setSearchCoordinates(null);
            this.showPostCodeError = true;
            resolve(false)
          }
        });
      } else {
        this.showPostCodeError = true;
        this.setSearchCoordinates(null);
        resolve(false)
      }
    });
  }

  setQueryParamObjBasedOnSelection() {
    this.searchFilterQueryParams = {
      ...this.selectedSchoolTypeId && { selectedSchoolTypeId: this.selectedSchoolTypeId },
      ...this.selectedCourses?.length && { coursesId: this.selectedCourseIds },
      ...this.selectedLevelId && { levelId: this.selectedLevelId },
      ...this.selectedYearId && { yearId: this.selectedYearId },
      ...this.postCode && { postCode: this.postCode },
      ...this.isOnline === true && { online: this.isOnline },
      ...this.randomSeed && { randomSeed: this.randomSeed },
      ...this.searchCoordinates?.latitude && { lat: this.searchCoordinates.latitude },
      ...this.searchCoordinates?.longitude && { lon: this.searchCoordinates.longitude },
    };

  }

  navigateToSearch(backToScrollView: boolean = false) {
    this.translationService.get('ROUTES.Misc_tutor_search').subscribe(translation => {
      this.backToScrollView = backToScrollView;
      this.router.navigate([translation], { queryParams: this.searchFilterQueryParams });
    });
  }

  private setSearchCoordinates(coordinates: Coordinates): void {
    this.searchCoordinates = coordinates ? coordinates : null;
  }

  private setAvailableCoursesByLevel() {
    if (this.selectedSchoolLevel.courses) {
      this.availableCourses = this.selectedSchoolLevel.courses;
    } else {
      this.availableCourses = [];
      this.selectedSchoolLevel.years.forEach(year => {
        this.createAvailableCourses(this.availableCourses, year.courses)
      })
    }
  }

  private setAllCourses() {
    this.schoolTypes.forEach(schoolType => {
      schoolType.levels.forEach(level => {
        if (level.courses) {
          this.createAvailableCourses(this.allCourses, level.courses)
        } else {
          level.years.forEach(year => {
            this.createAvailableCourses(this.allCourses, year.courses)
          })
        }
      })
    })
    this.setSubjectsForHomepage()
  }

  private setSubjectsForHomepage() {
    this.popularCourses = this.allCourses.filter(course => {
      if (course.isPopular) {
        if (this.utilityService.isNL) {
          if (course.id == 7) {
            course.icon = CourseIcons.Comments
          } else if (course.id == 8 || course.id == 28 || course.id == 29 || course.id == 31) {
            course.icon = CourseIcons.Abacus
          } else if (course.id == 26) {
            course.icon = CourseIcons.AtomAlt
          } else if (course.id == 25) {
            course.icon = CourseIcons.Microscope
          }
        } else {
          if (course.id == 41 || course.id == 42 || course.id == 44 || course.id == 47) {
            course.icon = CourseIcons.Comments
          } else if (course.id == 48) {
            course.icon = CourseIcons.Abacus
          }
        }
        return course
      }
    })
  }

  private filterSelectedCoursesByAvailableCourses() {
    this.selectedCourseIds = this.selectedCourseIds.filter(courseId => this.availableCourses.find(course => course.id === courseId))
    this.selectedCourses = this.selectedCourses.filter(course => this.availableCourses.find(availableCourse => availableCourse.id === course.id))
  }

  private createAvailableCourses(allCourses: Array<Course>, newCourses: Array<Course>) {
    newCourses.forEach(newCourse => {
      const i = allCourses.findIndex(course => course.id === newCourse.id)
      if (i === -1) {
        allCourses.push(newCourse)
      }
    })
    allCourses.sort((a, b) => a.name.localeCompare(b.name))
  }
}