import { DialogDataModel } from './../models/dialog-data.model';
import { DialogCoreComponent } from './../modules/shared/dialogs/dialog-core/dialog-core.component';
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from './utility.service';
import { InfoDialogComponent } from 'app/modules/shared/dialogs/info-dialog/info-dialog.component';
import { LoginDialogComponent } from 'app/standalone-components/login-or-register/login-dialog/login-dialog.component';
import { TutorRegisterDialogComponent } from 'app/standalone-components/login-or-register/tutor-register-dialog/tutor-register-dialog.component';
import { StudentRegisterDialogComponent } from 'app/standalone-components/login-or-register/student-register-dialog/student-register-dialog.component';
import { SearchDialogComponent } from 'app/standalone-components/search-dialog/search-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class PopupService {

	isModal: boolean = false;
	fullScreenModalConfig: MatDialogConfig = {
		minWidth: '100vw',
		maxHeight: '100%',
	}

	//TODO remove when refactor the booking flows
	onClose = new EventEmitter();

	private fullScreenDialogConfig = {
		minWidth: '100vw',
		maxWidth: '100vw'
	}

	constructor(
		private utilityService: UtilityService,
		private matDialog: MatDialog
	) { }

	showLoginModal() {
		this.utilityService.trackEvent('popup', 'login', 'show');
		return this.openResponsiveDialog(LoginDialogComponent, this.fullScreenModalConfig, true)
	}

	showRegisterModalStudent() {
		this.utilityService.trackEvent('popup', 'register', 'show');
		return this.matDialog.open(StudentRegisterDialogComponent, this.fullScreenDialogConfig)
	}

	showRegisterModalTutor() {
		this.utilityService.trackEvent('popup', 'register', 'show');
		return this.matDialog.open(TutorRegisterDialogComponent, this.fullScreenDialogConfig)
	}

	showSearchDialog() {
		return this.openResponsiveDialog(SearchDialogComponent, this.fullScreenModalConfig, true)
	}

	openResponsiveDialog(dialogComp, config?: MatDialogConfig, skipAnimation: boolean = false): MatDialogRef<any> {
		const configuration: MatDialogConfig = {
			maxWidth: "100vw",
			minWidth: config?.minWidth ? config.minWidth : "",
			maxHeight: config?.maxHeight ? config.maxHeight : "95vh",
			enterAnimationDuration: (this.utilityService.isDesktopRes || skipAnimation) ? 200 : 0,
			exitAnimationDuration: (this.utilityService.isDesktopRes || skipAnimation) ? 200 : 0,
			panelClass: config?.panelClass,
			disableClose: true,
		}
		const dialogRef = this.matDialog.open(dialogComp, configuration);
		if (!skipAnimation) {
			this.addAnimationToDialog(dialogRef)
		}
		return dialogRef;
	}

	openInfoDialog(data: DialogDataModel) {
		const dialog = this.openResponsiveDialog(DialogCoreComponent, { panelClass: ['default-dialog'], disableClose: true });
		const _component = dialog.componentInstance;
		_component.title = data.title;
		_component.childComponent = InfoDialogComponent;
		_component.onChildReady.subscribe((childInstance: InfoDialogComponent) => {
			childInstance.description = data.description
		})

		if (data.trackEvent) {
			this.trackDialogEvent(data.trackEvent, dialog)
		}
		return dialog;
	}

	private addAnimationToDialog(dialogRef: MatDialogRef<any>) {
		const dialogContainer = dialogRef.componentRef.location.nativeElement.firstElementChild
		if (!this.utilityService.isMobileRes) {
			return
		}
		dialogContainer.animate([
			{ transform: 'translateY(100%)' },
			{ transform: 'translateY(0%)' },
		], {
			duration: 200,
			easing: 'ease-out'
		});
		const originalCloseMethod = dialogRef.close
		dialogRef.close = () => {
			const animation = dialogContainer.animate([
				{ transform: 'translateY(0)' },
				{ transform: 'translateY(100%)' }
			], {
				duration: 200,
				easing: 'ease-out'
			});
			animation.onfinish = () => {
				dialogRef.close = originalCloseMethod;
				dialogRef.close();
			};
		}
	}

	private trackDialogEvent(trackEvent: string, dialog: MatDialogRef<any>) {
		this.utilityService.trackEvent('popup', trackEvent, 'show')
		dialog.afterClosed().subscribe(() => {
			this.utilityService.trackEvent('popup', trackEvent, 'hide')
		})
	}

}