<div class="logo-group" [ngClass]="{'logo-group--nl': this.utilityService.country == 'nl'}">
	<ul class="logo-group-list">
		<li class="logo-group-list__item item-carousel">
			<div class="carousel-seciton-container" *ngIf="utilityService.isNL">
				<app-custom-carousel [showDots]="true" [gap]="12" [autoScroll]="true">
					<div class="logo-container">
						<div *ngFor="let image of mySectionImages; let i = index">
							<picture class="carousel-item">
								<source media="(min-width:992px)" [srcset]="image.sources?.large">
								<source media="(min-width:576px)" [srcset]="image.sources?.tablet">
								<source [srcset]="image.sources?.mobile">
								<img class="picture" [lazyLoad]="image?.originalSource" [alt]="image.title">
							</picture>
						</div>
					</div>
				</app-custom-carousel>
			</div>
			<div *ngIf="utilityService.isDE" class="award-container">
				<app-image-defer-lazy [image]="awardImage"></app-image-defer-lazy>
			</div>
		</li>
		<li class="logo-group-list__item logo-group-list__item--review-widget">
			<app-google-default-review></app-google-default-review>
		</li>
		<li class="logo-group-list__item">
			<div class="partner-logo">
				<a [routerLink]="['/Navigation_stichting'] | localize" role="link" class="partner-logo__link">
					<img [src]="'images.LogoGroupComponent_stichting_logo.originalSource' | translate"
						[alt]="'images.LogoGroupComponent_stichting_logo.title' | translate" class="partner-logo__img">
				</a>
				<a [routerLink]="['/Navigation_stichting'] | localize" class="logo-group-list__text--small"
					[innerHTML]="'texts.LogoGroupComponent_stichting_text' | translate"></a>
			</div>
		</li>
	</ul>
</div>