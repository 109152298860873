<div class="section-flex section-active">
    <div class="section-title title " [class.titleGreen]="componentStyle">
        {{'texts.Price_Page_Trustpilot_Section_Title'|translate}}
    </div>
    <div class="toggle-and-google-review-container">
        <div class="button-toggle-container">
            <a class=" button-toggle" [class.button-toggle-active]="!isStudent"
                (click)=" swapData(false)">{{'texts.Price_Page_Trustpilot_Section_Button_Parents'|
                translate }}</a>
            <a class=" button-toggle" [class.button-toggle-active]="isStudent"
                (click)="swapData(true)">{{'texts.Price_Page_Trustpilot_Section_Button_Students'|
                translate }}</a>
        </div>
        <app-google-one-line-review *ngIf="!utilityService.isMobileRes"
            class="google-one-line-container"></app-google-one-line-review>
    </div>
    <div class="testimonial-container" *ngIf="testimonials?.length > 0">
        <app-custom-carousel #customCarousel [gap]=20 [isNavigationAllowed]="utilityService.isMobileRes"
            [showDots]="true">
            <div class="review-cards-container">
                <div class="testimonial-card studentCard carousel-card" *ngFor="let data of testimonials" #slideCard>
                    <div class="testimonial-card-top">
                        <div class="round-picture-container" *ngIf="data._imageObj">
                            <app-image-defer-lazy [image]="data._imageObj"></app-image-defer-lazy>
                        </div>
                        <div class="testimonial-top-container">
                            <div class="testimonial-name subtitle" [disabled]>{{data.name}}</div>
                            <app-google-stars [rating]="data.rating"></app-google-stars>
                        </div>
                    </div>
                    <div class="testimonial-card-bottom text" [disabled]> {{data.feedback}}</div>
                </div>
            </div>
        </app-custom-carousel>
    </div>
    <app-google-one-line-review *ngIf="utilityService.isMobileRes"
        class="google-one-line-container"></app-google-one-line-review>
</div>