import { Component, ElementRef, HostListener, Input, QueryList, ViewChild, OnDestroy, signal, ChangeDetectorRef } from '@angular/core';
import { UtilityService } from '../../services';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-carousel.component.html',
  styleUrl: './custom-carousel.component.scss'
})
export class CustomCarouselComponent implements OnDestroy {

  @ViewChild('container') container: ElementRef;
  @Input('gap') gap: number = 12;
  @Input() showDots: boolean = false;
  @Input() autoScroll: boolean = false;
  @Input() autoScrollInterval: number = 5000;
  @Input() isNavigationAllowed: boolean = true;
  @Input('activeTabIndex') activeTabIndex = signal(0);

  carouselSlides: QueryList<ElementRef>;
  currentTranslate = 0;
  maxSlide: number;
  swipeStarX: number;
  private autoScrollTimer: any;
  dots = signal<any[]>([]);
  constructor(
    private utilityService: UtilityService,
    private cdr: ChangeDetectorRef
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.activeTabIndex.set(0);
    this.currentTranslate = 0;
    this.container.nativeElement.firstChild.style.transform = `translateX(${this.currentTranslate}px)`;
  }

  ngAfterViewInit(): void {
    this.carouselSlides = this.container.nativeElement.firstChild.childNodes;
    this.maxSlide = this.carouselSlides.length - 2;
    this.container.nativeElement.firstChild.classList.add('carousel-container');
    this.container.nativeElement.firstChild.style.transform = `translateX(${this.currentTranslate}px)`;
    this.container.nativeElement.firstChild.style.gap = this.gap + 'px';
    this.dots.set(Array(this.carouselSlides.length - 1))
    this.cdr.detectChanges();

    this.carouselSlides.forEach((tab: any) => {
      if (tab.nodeName !== '#comment') {
        tab.classList.add('carousel-card');
        return;
      }
      return tab
    });
    if (this.autoScroll && this.utilityService.isBrowser) {
      this.startAutoScroll();
    }
  }

  goToSlide(index: number) {
    this.activeTabIndex.set(index);
    this.currentTranslate = -(this.carouselSlides[0].offsetWidth + this.gap) * index;
    this.container.nativeElement.firstChild.style.transform = `translateX(${this.currentTranslate}px)`;
  }

  ngOnDestroy() {
    this.stopAutoScroll();
  }

  private startAutoScroll() {
    this.autoScrollTimer = setInterval(() => {
      this.nextSlide();
    }, this.autoScrollInterval);
  }

  private stopAutoScroll() {
    if (this.autoScrollTimer) {
      clearInterval(this.autoScrollTimer);
      this.autoScrollTimer = null;
    }
  }

  onTouchStart($event: TouchEvent) {
    this.swipeStarX = $event.touches[0].clientX
    if (this.autoScroll) {
      this.stopAutoScroll();
    }
  }

  onTouchEnd($event: TouchEvent) {
    const swipeDistance = this.swipeStarX - $event.changedTouches[0].clientX

    if (this.autoScroll) {
      this.startAutoScroll();
    }

    if (swipeDistance > 80) {
      this.nextSlide()
      return
    }

    if (swipeDistance < -80) {
      this.prevSlide()
      return
    }
  }

  nextSlide() {
    if (!this.isNavigationAllowed) {
      return;
    }
    this.activeTabIndex.update(prev => prev + 1);
    if (this.activeTabIndex() == this.dots().length) {
      this.activeTabIndex.set(0);
    }
    this.move();
  }

  prevSlide() {
    if (!this.isNavigationAllowed) {
      return;
    }
    this.activeTabIndex.update(prev => prev - 1);
    if (this.activeTabIndex() === -1) {
      this.activeTabIndex.set(this.dots().length - 1);
    }
    this.move();
  }

  private move() {
    const slideWidth = this.carouselSlides[0].offsetWidth
    this.currentTranslate = -(slideWidth + this.gap) * this.activeTabIndex()
    this.container.nativeElement.firstChild.style.transform = `translateX(${this.currentTranslate}px)`;
  }
}
