import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopupService, UtilityService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';
import { SearchService } from 'app/services/search.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-searchbar-header-section',
	templateUrl: './searchbar-header-section.component.html',
	styleUrls: ['./searchbar-header-section.component.scss']
})
export class SearchbarHeaderSectionComponent extends CmsBaseComponent implements OnInit {
	showGoogleReviews: boolean;;

	@Input()
	description?: string
	language!: string
	@Input()
	title?: string

	@Input()
	showEmpathy: boolean = false;
	@Input()
	showLogoGroup: boolean = true;
	@Input()
	showStorybrandSteps: boolean = false;

	@ViewChild('postCodeInput') postCodeInput: ElementRef;

	@ViewChild('breakpoint', { static: false }) private breakpoint: ElementRef<HTMLDivElement>;
	isOneInput: boolean;
	isNewSearch: boolean;
	@HostListener('window:scroll', ['$event'])
	isScrolledIntoView() {
		if (this.breakpoint && this.route.snapshot.queryParams.isSticky === 'true') {
			const rect = this.breakpoint.nativeElement.getBoundingClientRect();
			const topShown = rect.top >= 0;
			const bottomShown = rect.bottom <= window.innerHeight;
			this.isOneInput = !topShown && bottomShown;
		}
	}

	constructor(
		public searchService: SearchService,
		public utilityService: UtilityService,
		private translationService: TranslateService,
		private popupService: PopupService,
		private route: ActivatedRoute,
		private router: Router
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.language = this.translationService.currentLang;
		if (this.route.snapshot.queryParams['new-search']) {
			this.isNewSearch = true;
		}
		if (this.route.snapshot.queryParams['bijles-google'])
			this.showGoogleReviews = true;
		if (this.description) {
			this.description = this.utilityService.unescapeHTML(this.description);
		}
		if (this.title) {
			this.title = this.utilityService.unescapeHTML(this.title);
		}
	}

	openSearchDialog() {
		const dialog = this.popupService.showSearchDialog()
		this.utilityService.addOpenQueryParam('isSearchOpen', this.route, this.router);
		this.popupService.onClose.subscribe(() => {
			dialog.close();
		})
	}
}