import { effect, Injectable, Input, signal, WritableSignal } from '@angular/core';
import { SequenceOptions } from '../constants/packages';
import { UserObj } from '../microservice-clients/user';
import { CustomCookieServiceService } from './custom-cookie-service.service';

export type StudentCases = {
  testCase2: Case | undefined;
}
export type CasesForLoggedOutUsers = {
  testCase5: boolean | undefined;
}

type Case = {
  option: number;
  adviced?: string;
}

@Injectable({
  providedIn: 'root'
})

export class AbTestService {

  @Input() user: WritableSignal<UserObj | null> = signal(null);

  studentCases: StudentCases
  testCaseForLoggedOutUsers: CasesForLoggedOutUsers
  sessionId: string;

  constructor(
    private customCookieService: CustomCookieServiceService,
  ) {
    this.sessionId = this.customCookieService.get('sessionIdForVWO');
  }

  initTestCases = effect(() => {
    if (this.user()?.studentDetails) {
      this.setAbTestDataForStudents();
    }
  });

  setAbTestDataForStudents() {
    const id = +this.user().id;
    this.studentCases = {
      testCase2: this.setDataForCase2(id),
    }
  }

  setDataForCase2(id: number): Case | undefined {
    const lastDigit = id % 10;
    // Option 1: ID ends in 0-3: show current 12 hour version and not the 16 hour
    if (lastDigit >= 0 && lastDigit <= 3) {
      return { option: 1, adviced: '2' };
    }

    const lessonOptionUnderTesting = {
      text: 'texts.Pricing_Page_Packages_lessons_per_week_4',
      textMonthly: 'texts.Pricing_Page_Packages_lessons_per_month_16',
      value: '4',
      isActive: false
    };
    if (SequenceOptions.length < 4) {
      SequenceOptions.unshift(lessonOptionUnderTesting);
    }

    // Option 2 ID ends with 4-5: show the 16 hour option, but do not have any ''advised'' badge on any option
    if (lastDigit === 4 || lastDigit === 5) {
      return { option: 2, adviced: '0' };
    }

    // ID ends with 6-7: show the 16 hour option, and make the middle 2 options (8 and 12) bigger than the 4 and 16 hour version, so they stand out more. There is not design for it, but I think it should be OK.
    if (lastDigit === 6 || lastDigit === 7) {
      return { option: 3, adviced: '0' };
    }

    // ID ends with 8-9: show the 16 hour option, and put the advice badge on 12 hours per week.
    if (lastDigit === 8 || lastDigit === 9) {
      return { option: 4, adviced: '3' };

    }
  }

  setDataForCase5(url: string) {
    console.log('url', url);
    let testValue = true
    if (this.user()) {
      testValue = true
    } else {
      if (url === '' || url === '/' || url.includes('/new-homepage') || url.includes('?new-search=true')) {
        // Based on sessionId we divide users into 2 groups. If session id is even we show the pricing page link in the menu on homepage, if it is odd we don't show it in the menu on homepage.
        const lastChar = this.sessionId[this.sessionId.length - 1];
        const group = parseInt(lastChar, 16) % 2; // Divide users into 2 groups (0 and 1)
        if (group === 0) {
          testValue = true
        } else {
          testValue = false
        }
      }
    }
    this.testCaseForLoggedOutUsers = {
      testCase5: testValue
    }
  }
}
