import { PersonalDataModel } from './../../../microservice-clients/user';
import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaComponent } from 'ng-recaptcha';
import { PersonalDataComponent } from '../personal-data/personal-data.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { ApiService, AuthService, LogService, ToastService, UtilityService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BypassAPIGlobalHandleEnums, LOG_TYPE, MessageType } from 'app/constants';
import { RegisterUserRequest } from 'app/microservice-clients/user';
import { catchError, of } from 'rxjs';
import { CustomTabsComponent } from 'app/standalone-components/custom-tabs/custom-tabs.component';
import { CustomCookieServiceService } from '../../../services/custom-cookie-service.service';

@Component({
    selector: '',
    template: '',
    standalone: true,
    imports: [CommonModule]
})
export abstract class RegisterBaseComponent<T> {
    hideSelectRegType: boolean = false;
    phoneNumberUtil: any;
    registrationForm: FormGroup;
    captcha_value: string;
    isRecaptchaDisabled: boolean;
    siteKey: string = environment.google.InvisibleCaptchaKey;
    registerUserRequest: RegisterUserRequest

    @Input() isCloseButtonVisible: boolean = true;
    @Input() userData: PersonalDataModel = {} as PersonalDataModel;

    onUserLoginComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('tabs') tabs: CustomTabsComponent
    @ViewChild("recaptchaComponent") reCaptcha: RecaptchaComponent;
    @ViewChild('personalDataRegister') personalDataRegister: PersonalDataComponent;

    protected abstract autoFillData(): void;
    protected abstract createRegistrationRequestData(): void;
    protected abstract triggerToast(id: string): void;

    constructor(
        public authService: AuthService,
        public translationService: TranslateService,
        public formBuilder: FormBuilder,
        public utilityService: UtilityService,
        public apiService: ApiService,
        public logService: LogService,
        public dialogRef: MatDialogRef<T>,
        public toastService: ToastService,
        public cookieService: CustomCookieServiceService,
    ) { }

    addPersonalDataToForm() {
        this.registrationForm.addControl('personalDataForm', this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern('^[\\w-]+(\\.[\\w-]+)*@[\\w-]+(\\.[\\w-]+)*(\\.[a-zA-Z]{2,})$')]],
            password: ['', [Validators.required]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]]
        }));
    }

    setRecaptcha() {
        if (environment.production) {
            this.isRecaptchaDisabled = false;
        } else {
            this.isRecaptchaDisabled = true
            this.captcha_value = "testCaptchaResponse";
        }
        this.isRecaptchaDisabled = environment?.production !== true;
    }

    onSubmit() {
        if (!this.isRecaptchaDisabled) {
            this.reCaptcha.execute();
        } else {
            this.postRegistrationRequest();
        }
    }

    reCaptchaError(event) {
        this.logService.log(LOG_TYPE.WARNING, "Resolved captcha with error", event);
    }

    checkToAutofill() {
        if (this.utilityService.isBrowser && location.search.includes('autofillBAH')) {
            setTimeout(() => {
                if (!this.userData.googleId) {
                    this.autoFillData();
                }
            });
        }
    }

    reCaptchaResolved(captchaResponse): void {
        if (captchaResponse) {
            this.captcha_value = captchaResponse;
            this.reCaptcha.reset();
            this.postRegistrationRequest();
            this.logService.log(LOG_TYPE.INFO, `Resolved captcha with response:`, captchaResponse);
        }
    }

    private handleLogin(accessToken: string) {
        this.authService.setUserFromAccessToken(accessToken);
        const subcribeToUserChange = this.authService.onCurrentUserChange.subscribe(() => {
            this.onUserLoginComplete.emit(true);
            subcribeToUserChange.unsubscribe();
            this.dialogRef.close();
        });
    }

    private postRegistrationRequest() {
        this.createRegistrationRequestData();
        const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All);
        this.apiService.postWithHeaders(`user/new`, this.registerUserRequest, headers).pipe(
            catchError((error) => {
                if (error.status === 409) {
                    this.tabs.goToTab(1);
                    this.personalDataRegister.emailDuplicateState = true;
                } else {
                    this.toastService.showMsg('texts.' + error.error.error.text_key, MessageType.error, { title: 'texts.Misc_error' });
                }
                return of(null);
            })
        ).subscribe((response) => {
            if (response) {
                this.triggerToast(response.id);
                this.handleLogin(response.access);
            }
        });
    }
}
