import { DragDropModule, CdkDragEnd } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { UtilityService, VariableService } from '../../services';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, signal, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-widget-support',
  standalone: true,
  imports: [CommonModule, TranslateModule, DragDropModule],
  templateUrl: './widget-support.component.html',
  styleUrl: './widget-support.component.scss'
})
export class WidgetSupportComponent implements OnInit {
  @ViewChild('widgetSupportContentContainer') widgetSupportContentContainer: ElementRef;

  isOpen = false;
  isDragging = false;

  readonly PADDING = 40;
  readonly ELEMENT_WIDTH = 60;
  readonly ELEMENT_HEIGHT = 65;
  readonly EDGE_THRESHOLD = 200;

  topPadding: number;
  position = signal({
    bottom: this.PADDING,
    right: this.PADDING
  });

  constructor(
    public variableService: VariableService,
    private utilityService: UtilityService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.topPadding = this.calculateTopPadding();
    if (this.router.url.includes('lessonspace') && this.utilityService.isBrowser) {
      this.position.set({
        bottom: window.innerHeight / 2,
        right: this.PADDING
      });
    }
  }

  private calculateTopPadding(): number {
    const navbarHeight = this.utilityService.isMobileRes ? 100 : 120;
    return this.utilityService.isSemiFullScreen ? this.PADDING : navbarHeight;
  }

  async closeWidget(): Promise<void> {
    const animation = this.widgetSupportContentContainer.nativeElement.animate([
      { transform: 'translateY(0)' },
      { transform: 'translateY(110%)' },
    ], {
      duration: 400,
      easing: 'ease-out',
      fill: 'forwards'
    });
    await animation.finished;
    this.isOpen = false;
  }

  onClick(): void {
    if (!this.isDragging) {
      this.isOpen = true;
    }
    this.isDragging = false;
  }

  onDragStarted(): void {
    this.isDragging = true;
  }

  onDragEnded(event: CdkDragEnd): void {
    const { x, y } = event.dropPoint;
    const newPosition = this.calculateNewPosition(x, y);

    if (newPosition) {
      event.source._dragRef.reset();
      this.position.set(newPosition);
    }

    setTimeout(() => {
      this.isDragging = false;
    });
  }

  private calculateNewPosition(x: number, y: number) {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const isNearLeft = x < this.PADDING + this.EDGE_THRESHOLD;
    const isNearRight = x > windowWidth - this.PADDING - this.EDGE_THRESHOLD;
    const isNearTop = y < this.topPadding;
    const isNearBottom = y > windowHeight - this.PADDING;

    if (!isNearLeft && !isNearRight && !isNearTop && !isNearBottom) {
      return null;
    }

    // Handle corners
    if (isNearLeft && isNearTop) {
      return {
        right: windowWidth - this.ELEMENT_WIDTH - this.PADDING,
        bottom: windowHeight - this.ELEMENT_HEIGHT - this.topPadding
      };
    } else if (isNearRight && isNearTop) {
      return {
        right: this.PADDING,
        bottom: windowHeight - this.ELEMENT_HEIGHT - this.topPadding
      };
    } else if (isNearLeft && isNearBottom) {
      return {
        right: windowWidth - this.ELEMENT_WIDTH - this.PADDING,
        bottom: this.PADDING
      };
    } else if (isNearRight && isNearBottom) {
      return {
        right: this.PADDING,
        bottom: this.PADDING
      };
    }

    // Handle edges
    if (isNearLeft) {
      return {
        right: windowWidth - this.ELEMENT_WIDTH - this.PADDING,
        bottom: windowHeight - y
      };
    } else if (isNearRight) {
      return {
        right: this.PADDING,
        bottom: windowHeight - y
      };
    } else if (isNearTop) {
      return {
        right: windowWidth - x - this.ELEMENT_WIDTH / 2,
        bottom: windowHeight - this.ELEMENT_HEIGHT - this.topPadding
      };
    } else if (isNearBottom) {
      return {
        right: windowWidth - x - this.ELEMENT_WIDTH / 2,
        bottom: this.ELEMENT_HEIGHT
      };
    }
    return null;
  }
}