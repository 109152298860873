import { UtilityService } from './utility.service';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as utc from 'dayjs/plugin/utc';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isBetween from 'dayjs/plugin/isBetween';

@Injectable({
    providedIn: 'root'
})
export class DayjsService {
    private initialized = false;

    constructor(
        private utilityService: UtilityService
    ) { }

    initDayjs() {
        this.extendDayjs();
        this.initializeLocales();
    }

    setLocale(locale: string) {
        dayjs.locale(locale);
    }

    isInitialized(): boolean {
        return this.initialized;
    }

    private async initializeLocales() {
        try {
            await Promise.all([
                import('dayjs/locale/nl'),
                import('dayjs/locale/de')
            ]);
            dayjs.locale(this.utilityService.country);
            this.initialized = true;
        } catch (error) {
            console.error('Failed to load dayjs locales:', error);
        }
    }

    private extendDayjs() {
        dayjs.extend(dayjsDuration);
        dayjs.extend(utc);
        dayjs.extend(isSameOrBefore);
        dayjs.extend(isSameOrAfter);
        dayjs.extend(isoWeek);
        dayjs.extend(isBetween);
    }
}