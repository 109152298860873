import { UserClientService } from './../../../services/user-client.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedModule } from '../../../modules/shared/shared.module';
import { UserRole } from '../../../constants';

interface HeardAboutUs {
  value: number;
  text: string;
}

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  animations: [
    trigger('errorMessage', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PhoneInputComponent implements OnInit {
  constructor(
    private userClientService: UserClientService
  ) { }

  heardAboutUsOptions: HeardAboutUs[] = []

  @Input() isRegisteringAsTutor: boolean = false;
  @Input() phoneNumber: FormControl;
  @Input() heardAboutUsForm?: FormControl;
  @Input() birthDay: FormControl;
  @Input() birthMonth: FormControl;
  @Input() birthYear: FormControl;

  @Output() onConfirm = new EventEmitter();
  @ViewChild('bottomContainer') bottomContainer: ElementRef;

  ngOnInit() {
    this.userClientService.getHeardAboutUsOptions(UserRole.teacher).subscribe((response) => {
      this.heardAboutUsOptions = response.options;
    });
  }

  onConfirmClicked() {
    this.phoneNumber.markAsTouched();
    if (this.isRegisteringAsTutor) {
      this.birthDay?.markAsTouched();
      this.birthMonth?.markAsTouched();
      this.birthYear?.markAsTouched();
    }

    if (this.isRegisteringAsTutor && this.heardAboutUsForm?.value == '') {
      this.heardAboutUsForm.markAsTouched();
    }

    if (this.phoneNumber.valid &&
      (!this.isRegisteringAsTutor || (this.birthDay.valid && this.birthMonth.valid && this.birthYear.valid && this.isValidDate())) &&
      (this.heardAboutUsForm?.valid || !this.isRegisteringAsTutor)) {
      this.onConfirm.emit();
    }
  }

  private isValidDate(): boolean {
    const date = new Date(
      this.birthYear.value,
      this.birthMonth.value - 1,
      this.birthDay.value
    );

    return !!date.getTime() &&
      date.getFullYear() >= 1940 &&
      date.getFullYear() <= 2020;
  }
  scrollToBottom() {
    this.bottomContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}