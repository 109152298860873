import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqItem } from 'app/models';
import { CustomExpansionPanelComponent } from '../custom-expansion-panel/custom-expansion-panel.component';
import { SharedModule } from 'app/modules/shared/shared.module';
import { UtilityService } from 'app/services';

@Component({
  selector: 'app-faq-list',
  standalone: true,
  imports: [CommonModule, SharedModule, CustomExpansionPanelComponent],
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent {
  expandedPanelIndex

  @Input() faqTitle: string;
  @Input() faqListItems: Array<FaqItem>;
  @Input() isChatPage: boolean = false;
  @Input() isLandingPage: boolean = false;

  constructor(
    private utilityService: UtilityService
  ) { }

  setStep(index: number) {
    if (index != this.expandedPanelIndex) {
      this.utilityService.trackEvent('faq_question', '', this.faqListItems[index].title);
      this.expandedPanelIndex = index;
    } else {
      this.expandedPanelIndex = null
    }
  }
}