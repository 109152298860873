<div class="widget-support-container" #widgetCTA cdkDrag *ngIf="!isOpen" cdkDragBoundary="body" (click)="onClick()"
    (cdkDragStarted)="onDragStarted()" (cdkDragEnded)="onDragEnded($event)" [style.bottom.px]="position().bottom"
    [style.right.px]="position().right">
    <div class="icon-container">
        <i class="fas fa-user-headset"></i>
    </div>
    <i class="fas fa-grip-vertical"></i>
</div>
<div class="widget-support-content-container" *ngIf="isOpen" #widgetSupportContentContainer>
    <i class="far fa-times close-button" (click)="closeWidget()"></i>
    <div class="content-header">
        <div class="content-title" translate>texts.Widget_Content_Title</div>
        <div class="content-description" translate>texts.Widget_Content_Description</div>
    </div>
    <div class="contact-methods">
        <div class="contact-method">
            <div class="subtitle" translate>texts.Widget_Content_Call_Us</div>
            <div class="cta-container">
                <i class="far fa-phone-alt"></i>
                <a class="cta-text" [href]="'tel:' + (variableService.contactVariable$ | async)?.contactPhoneLinked">
                    {{(variableService.contactVariable$ | async)?.contactPhoneDisplayed}}
                    <span translate>texts.Contact_Widget_Opening_Hours</span>
                </a>
            </div>
        </div>
        <div class="contact-method">
            <div class="subtitle" translate>texts.Widget_Content_Mail_Us</div>
            <div class="cta-container">
                <i class="far fa-envelope"></i>
                <a class="cta-text" [href]="'mailto:' + (variableService.contactVariable$ | async)?.contactEmail">
                    {{(variableService.contactVariable$ | async)?.contactEmail}}
                </a>
            </div>
        </div>
        <a class="chat-button" [href]="(variableService.contactVariable$ | async)?.contactWhatsappLink" target="_blank">
            <i class="fab fa-whatsapp"></i>
            <span translate>texts.Widget_Content_Chat_with_Us</span>
        </a>
    </div>
    <div class="features-list">
        <div class="feature-item" *ngFor="let feature of ['Cashback', 'Free_Profile', 'Guarantee']">
            <i class="fal fa-check"></i>
            <div class="feature-text" translate>
                {{'texts.Widget_Content_' + feature}}
            </div>
        </div>
    </div>
</div>