import { PagerModel } from './../models/pager.model';
import { SessionRecording } from './../models/session-recording.model';
import { AvailabilitySlotRequestModel, TutorweeklyAvailabilityModel } from './../models/availability.model';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { AuthService, UtilityService } from 'app/services';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { Appointment, BookingDataModel, BookingResponseModel, OverviewFilterResponseModel, OverviewAppointmentFilterModel, RecurrentBookingDataModel, EditDataModel } from 'app/models';
import { YourConnectionModel } from 'app/modules/dashboard/models/your-connection.model';
import { AppointmentType, BypassAPIGlobalHandleEnums } from 'app/constants';
import { GetTutorLevelsResponse, LessonPrices, WeeklyPricingRequestDetails } from 'app/microservice-clients/user';
import { AppointmentHours } from 'app/models/day-time-and-hours';

let cache = {};
@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private tutorWeeklyAvailabilityAPIPath = (tutorId: string) => `api/booking/availability/${tutorId}/week?slots=1`;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private utilityService: UtilityService
  ) { }

  public getRelationAvailableDates(userID: string, type: number, exclude?: string, startDate: string = dayjs().add(1, 'days').format('YYYY-MM-DD'), endDate: string = dayjs().add(1, 'year').format('YYYY-MM-DD')) {
    return this.apiService.get(`api/booking/availability/${userID}/${type}?begin=${startDate}&end=${endDate}${(exclude) ? '&exclude=' + exclude : ''}`)
      .pipe(map(response => {
        return response ? response.dates : [];
      }));
  }

  public getRelationTimeDuration(tutorID: string, type: number, selectedDate: string, exclude?: string): Observable<any> {
    return this.apiService.get(`api/booking/availability/${tutorID}/${type}?begin=${selectedDate}&end=${selectedDate}${(exclude) ? '&exclude=' + exclude : ''}`)
      .pipe(map(response => {
        //@ts-ignore
        return Object.values(response.data)[0] ? Object.values(response.data)[0].times : [];
      }));
  }

  public createBooking(bookingData: BookingDataModel, type: number = AppointmentType.lesson): Observable<BookingResponseModel> {
    const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All);
    const apiUrl = `api/booking/appointment/${type}`;
    return this.apiService.postWithHeaders(apiUrl, bookingData, headers)
      .pipe(map(response => {
        return response ? response.appointment : null;
      }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public createRecurrentBooking(bookingData: RecurrentBookingDataModel): Observable<{}> {
    const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All);
    const apiUrl = `api/booking/schedule/weekly`;
    return this.apiService.postWithHeaders(apiUrl, bookingData, headers).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  public updateBooking(bookingData: EditDataModel): Observable<any> {
    return this.apiService.put(`api/booking/appointment/${bookingData.id}`, bookingData);
  }

  public acceptAppointment(appointmentID: string): Observable<any> {
    return this.apiService.patch(`api/booking/appointment/${appointmentID}/accept`)
  }

  public cancelDeleteAppointment(appointmentID: string): Observable<any> {
    return this.apiService.delete(`api/booking/appointment/${appointmentID}`)
  }

  public getOnlineSessionRecordings(tutorID: string, studentID: string, offset: number, limit: number): Observable<{ sessions: Array<SessionRecording>, page: PagerModel }> {
    return this.apiService.get(`api/booking/appointment/sessions/${tutorID}/${studentID}?offset${offset}&limit=${limit}`).pipe(catchError(error => this.handle404(error)));
  }

  public getRelationAppointments(tutorID, studentID, startDate): Observable<any> {
    return this.apiService.get(`api/booking/appointment?tutor=${tutorID}&student=${studentID}&from=${startDate}`).pipe(catchError(error => this.handle404(error)));
  }

  public getUpComingAppointment(): Observable<Appointment> {
    const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.ToastMessageHandle);
    const apiUrl = `api/booking/appointments/${this.authService.user.id}/upcoming`
    return this.apiService.getWithHeaders(apiUrl, headers).pipe(catchError(error => this.handle404(error)));
  }

  public getTutorAppointments(tutorId, date): Observable<AppointmentHours> {
    return this.apiService.post(`api/booking/appointments/${tutorId}/hours`, { date: date })
  }

  public getAppointmentsForUser(begin: string, end: string, courseID: number = null): Observable<Array<Appointment>> {
    return this.apiService.post(`api/booking/appointment/user/${this.authService.user.id}`, {
      begin: begin,
      end: end,
      subjectId: courseID,
    }).pipe(map(response => {
      return response ? response.appointments : [];
    }));
  }

  public getUserRelations(): Observable<{ relations: Array<YourConnectionModel> }> {
    const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.ToastMessageHandle);
    const apiUrl = `api/booking/appointments/${this.authService.user.id}/relations`;
    return this.apiService.getWithHeaders(apiUrl, headers).pipe(catchError(error => this.handle404(error)));
  }

  public getLessonsSpaceUrl(studentId: string, tutorId: string): Observable<{ joinUrl: string }> {
    return this.apiService.post('api/booking/space/join', {
      tutor: tutorId,
      student: studentId
    })
  }

  public getTutorWeeklyAvailability(tutorId: string): Observable<TutorweeklyAvailabilityModel> {
    const path = this.tutorWeeklyAvailabilityAPIPath(tutorId);
    return cache.hasOwnProperty(path) ? cache[path] : this.apiService.get(path + `&cacheBust=${Date.now()}`).pipe(tap(response =>
      cache[path] = of(response)));
  }

  public addTutorAvailability(period: AvailabilitySlotRequestModel): Observable<{ id: number }> {
    this.resetTutorAvailabilityCache(period.tutorId);
    return this.apiService.post(`api/booking/availability/${this.authService.user.id}`, period).pipe(catchError(error => this.handle404(error)));
  }

  public deleteTutorAvailability(period: AvailabilitySlotRequestModel): Observable<{ id: number }> {
    this.resetTutorAvailabilityCache(period.tutorId);
    return this.apiService.delete(`api/booking/availability/${this.authService.user.id}/${period.day}/${period.period}`).pipe(catchError(error => this.handle404(error)));
  }

  public getRelationBookingPrice(userID: string | number, durationInMinutes: number, lat?: number, lon?: number): Observable<LessonPrices> {
    return this.apiService.get(`api/booking/appointment/pricing/${userID}?duration=${durationInMinutes}${(lat && lon) ? `&latitude=${lat}&longitude=${lon}` : ''}`).pipe(catchError(error => this.handle404(error)));
  }

  public getRelationBookingWeeklyPrice(weeklyPricingRequestDetails: WeeklyPricingRequestDetails): Observable<LessonPrices> {
    return this.apiService.post(`api/booking/schedule/weekly-pricing`, weeklyPricingRequestDetails)
  }

  public getPastAppointmentsFilter(filter: OverviewAppointmentFilterModel = null): Observable<OverviewFilterResponseModel> {
    return this.apiService.post(`api/booking/appointment/filter/${this.authService.user.id}`, filter)
  }

  public getTutorLevels(): Observable<GetTutorLevelsResponse> {
    const path = `api/booking/tutor-levels?country=${this.utilityService.country}`
    return cache.hasOwnProperty(path) ? cache[path] : this.apiService.get(path).pipe(tap(response =>
      cache[path] = of(response)));
  }

  public getPackagePaymentIntentRedirectUrl(packageId: string): Observable<{ url: string }> {
    return this.apiService.post(`api/booking/payment/intent/${packageId}`).pipe(tap(response => {
      return response?.url;
    }))
  }

  private resetTutorAvailabilityCache(tutorId: string) {
    const path = this.tutorWeeklyAvailabilityAPIPath(tutorId);
    if (cache.hasOwnProperty(path)) {
      delete cache[path];
    }
  }

  private handle404(error) {
    if (error.status == 404) return of(null);
    return throwError(error);
  }
}
