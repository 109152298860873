<div class="dialog-title" translate>texts.Student_Registration_Parent_Information_Title</div>
<div class="info-text" *ngIf="!isRegisteringAsTutor" translate>texts.Student_Registration_Parent_Information_Text</div>
<div class="info-text" *ngIf="isRegisteringAsTutor" translate>texts.Tutor_Registration_Phone_Description</div>
<div class="input-title" *ngIf="!isRegisteringAsTutor" translate>texts.Misc_parent_phone_number_field_title</div>
<div class="input-title" *ngIf="isRegisteringAsTutor" translate>texts.Misc_PhoneNumber</div>
<div class="input-field">
    <input *ngIf="phoneNumber" class="input-field" type="tel" id="telephone" #phoneInput [formControl]="phoneNumber"
        [placeholder]="'texts.Misc_field_phone' | translate" required>
</div>
<label *ngIf="phoneNumber?.invalid && (phoneNumber?.dirty || phoneNumber?.touched) || phoneNumber?.hasError('required')"
    class="error-message phone-error" [@errorMessage]>
    {{ 'texts.Misc_field_phone_message_error' | translate }}
</label>
<div class="select-container" *ngIf="heardAboutUsForm">
    <div class="input-title" translate>texts.Misc_where_hear_about_label</div>
    <ng-select class="heard-about-select" [formControl]="heardAboutUsForm" (click)="scrollToBottom()"
        [searchable]="false" dropdownPosition="bottom" [clearable]="false" placeholder="{{ 'texts.Misc_where_hear_about_label'
        }}">
        <ng-option *ngFor="let heardAboutUsOption of heardAboutUsOptions" [value]="heardAboutUsOption.id"
            [ngClass]="{'option-active': heardAboutUsOption.id === heardAboutUsOptions.id}">
            <span translate>{{ heardAboutUsOption.text }}</span>
        </ng-option>
    </ng-select>
    <label *ngIf="heardAboutUsForm?.touched && heardAboutUsForm?.hasError('required')" class="error-message"
        [@errorMessage]>
        {{ 'texts.Misc_required_invalid' | translate }}
    </label>
</div>
<div class="birthdate-field" *ngIf="isRegisteringAsTutor">
    <div class="input-title" translate>
        {{ 'texts.Misc_field_birthday' | translate }}
    </div>
    <div class="birthdate-inputs">
        <input *ngIf="birthDay" class="small-input-field input-field" [formControl]="birthDay" type="number"
            placeholder="DD" min="1" max="31">
        <input *ngIf="birthMonth" class="small-input-field input-field" [formControl]="birthMonth" type="number"
            placeholder="MM" min="1" max="12">
        <input *ngIf="birthYear" class="small-input-field input-field" [formControl]="birthYear" type="number"
            placeholder="YYYY" min="1940" max="2020">
    </div>
</div>
<label *ngIf="(birthDay?.touched || birthMonth?.touched || birthYear?.touched) && 
                  (birthDay?.invalid || birthMonth?.invalid || birthYear?.invalid)" class="error-message"
    [@errorMessage]>
    {{ 'texts.Misc_required_invalid' | translate }}
</label>
<div class="button next-button" (click)="onConfirmClicked()" translate>
    texts.Misc_register_button
</div>
<div #bottomContainer></div>