import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../../../services';
import { ImageDataModel } from 'app/models/image-translation.model';

@Component({
	selector: 'app-logo-group',
	templateUrl: './logo-group.component.html',
	styleUrls: ['./logo-group.component.scss']
})
export class LogoGroupComponent {

	reviewWidget = true;
	mySectionImages: Array<ImageDataModel>
	awardImage: ImageDataModel;

	@Input() showGoogle: boolean = false;

	constructor(
		public utilityService: UtilityService,
		private translationService: TranslateService,
	) { }

	ngOnInit() {
		this.getLogos();
	}

	getLogos() {
		this.translationService.get(['images.Family_award']).subscribe(translations => {
			this.awardImage = translations['images.Family_award'];
		});
		this.translationService.get([
			'images.LogoGroupComponent_img_1',
			'images.LogoGroupComponent_img_2',
			'images.LogoGroupComponent_img_3',
			'images.LogoGroupComponent_img_4',
			'images.LogoGroupComponent_img_5',

		]).subscribe(translations => {
			this.mySectionImages = Object.values(translations).map(translation =>
				this.utilityService.convertICUTranslatedObject(translation)
			);
		});
	}
}
